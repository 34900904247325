import React, { useState } from "react";
import "./Login.css";
import { AuthProvider, useAuth } from './AuthContext';

const Login = () => {
  const { login } = useAuth();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [shake, setShake] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === '984asaWQESdxc') {
      login();
    } else {
      setError('Invalid password');
    }
  };

  return (
    <div className="login-container">
      <div className={`login-box ${shake ? "shake" : ""}`}>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <button type="submit">Login</button>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
