import React, { useState, useEffect } from "react";
import PartnerConfiguration from "./PartnerConfiguration";
import PartnerProviderManager from "./PartnersProvidersManager";
import { AuthProvider, useAuth } from './AuthContext';
import Login from "./Login";
import "./App.css";



const AppContent = () => {
  const { isAuthenticated, logout } = useAuth();
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState("partnerConfiguration");

  useEffect(() => {
    let timer;
    if (message && message.includes("successfully")) {
      timer = setTimeout(() => {
        setMessage("");
      }, 5000); // Clear successful message after 5 seconds
    }
    return () => clearTimeout(timer);
  }, [message]);

  const callPartnerResourcesWorker = async (endpoint, method, body) => {
    console.log("Entering callPartnerResourcesWorker function");
    console.log(
      "PARTNER_RESOURCES_WORKER available:",
      typeof PARTNER_RESOURCES_WORKER !== "undefined"
    );
    console.log(
      "VITE_PARTNER_RESOURCES_WORKER_URL:",
      import.meta.env.VITE_PARTNER_RESOURCES_WORKER_URL
    );

    console.log(
      `Calling PARTNER_RESOURCES_WORKER - Endpoint: ${endpoint}, Method: ${method}`
    );
    let response;
    const headers = {
      "Content-Type": "application/json",
    };

    console.log("Request headers:", headers);
    console.log("Request body:", body);

    if (typeof PARTNER_RESOURCES_WORKER !== "undefined") {
      console.log("Using Cloudflare Worker binding for Partner Resources");
      try {
        response = await PARTNER_RESOURCES_WORKER.fetch(endpoint, {
          method,
          body: JSON.stringify(body),
          headers,
        });
        console.log("Successfully used PARTNER_RESOURCES_WORKER binding");
      } catch (error) {
        console.error("Error using PARTNER_RESOURCES_WORKER binding:", error);
      }
    } else {
      const PARTNER_RESOURCES_WORKER_URL = import.meta.env
        .VITE_PARTNER_RESOURCES_WORKER_URL;
      console.log(
        "Using VITE_PARTNER_RESOURCES_WORKER_URL:",
        PARTNER_RESOURCES_WORKER_URL
      );
      if (!PARTNER_RESOURCES_WORKER_URL) {
        console.error(
          "PARTNER_RESOURCES_WORKER_URL is not defined in environment variables"
        );
        throw new Error(
          "PARTNER_RESOURCES_WORKER_URL is not defined in environment variables"
        );
      }
      try {
        response = await fetch(`${PARTNER_RESOURCES_WORKER_URL}${endpoint}`, {
          method,
          body: JSON.stringify(body),
          headers,
        });
        console.log("Successfully used VITE_PARTNER_RESOURCES_WORKER_URL");
      } catch (error) {
        console.error("Error using fetch API:", error);
      }
    }

    console.log("Exiting callPartnerResourcesWorker function");

    if (!response) {
      console.error("No response received from PARTNER_RESOURCES_WORKER");
      throw new Error("Failed to get response from PARTNER_RESOURCES_WORKER");
    }

    return response;
  };

  const handleSubmit = async (partnerData) => {
    setIsSubmitting(true);
    setMessage("");

    const submitData = async () => {
      const response = await callPartnerResourcesWorker(
        "/insert-partner",
        "POST",
        partnerData
      );

      if (!response.ok) {
        throw new Error("Failed to insert partner configuration");
      }

      return "Partner configuration uploaded successfully!";
    };

    const retrySubmit = async (retries = 3) => {
      try {
        const result = await submitData();
        setMessage(result);
      } catch (error) {
        if (retries > 0) {
          setMessage(
            `Submission failed. Retrying... (${retries} attempts left)`
          );
          setTimeout(() => retrySubmit(retries - 1), 2000);
        } else {
          throw error;
        }
      }
    };

    try {
      await retrySubmit();
    } catch (error) {
      console.error("Error submitting partner data:", error);
      setMessage(
        "Failed to submit partner data after multiple attempts. Please try again later."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <div className="app-container">
      <div className="app-content">
        <header className="app-header">
          <div className="header-content">
            <h1 className="app-title">Partner Management System</h1>
            <p className="app-subtitle">Configure and manage your partners with ease</p>
          </div>
          <button onClick={logout} className="logout-button">
            Logout
          </button>
        </header>

        <div className="tab-container">
          <button
            className={`tab-button ${
              activeTab === "partnerConfiguration" ? "active" : ""
            }`}
            onClick={() => setActiveTab("partnerConfiguration")}
          >
            Partner Configuration
          </button>
          <button
            className={`tab-button ${
              activeTab === "providerManager" ? "active" : ""
            }`}
            onClick={() => setActiveTab("providerManager")}
          >
            Provider Manager
          </button>
        </div>

        <div className="tab-content">
          {activeTab === "partnerConfiguration" ? (
            <PartnerConfiguration onSubmit={handleSubmit} />
          ) : (
            <PartnerProviderManager />
          )}
        </div>

        {message && <div className="message">{message}</div>}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
