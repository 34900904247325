import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./PartnerConfiguration.css";

const MAX_FINE_TUNING_CHARS = 16000; // Approximately 4000 tokens

const Input = ({ label, ...props }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <input
      {...props}
      className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
    />
  </div>
);

const PartnerConfiguration = ({ onSubmit }) => {
  const initialPartnerState = {
    id: "",
    name: "",
    description: "",
    theme: {
      primaryColor: "#4a90e2",
      secondaryColor: "#50e3c2",
      headerColor: "#ff6b6b",
    },
    logo: null,

    resourceLinks: [],
    fineTuningText: "",
    welcomeMessage: "",
    isPublic: true,
  };

  const [partner, setPartner] = useState(initialPartnerState);
  const [resourceLink, setResourceLink] = useState({
    type: "url",
    title: "",
    url: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [searchPartnerId, setSearchPartnerId] = useState("");
  const [editingResourceLink, setEditingResourceLink] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    let timer;
    if (successMessage) {
      timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000); // Clear message after 5 seconds
    }
    return () => clearTimeout(timer);
  }, [successMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPartner((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const callPartnerResourcesWorker = async (endpoint, method, body) => {
    console.log("========= Entering callPartnerResourcesWorker =========");
    const requestDetails = {
      endpoint,
      method,
      bodyType: typeof body,
      isFormData: body instanceof FormData,
    };
    console.log("Request details:", requestDetails);

    let response;
    let headers = {};
    let requestBody = body;

    // Only set Content-Type and stringify for non-FormData bodies
    if (!(body instanceof FormData)) {
      console.log("Request contains JSON data, setting appropriate headers");
      headers["Content-Type"] = "application/json";
      requestBody = JSON.stringify(body);
      console.log("Stringified request body:", requestBody);
    } else {
      console.log("Request contains FormData, preserving original format");
      console.log("FormData entries:", Array.from(body.entries()));
    }

    if (typeof PARTNER_RESOURCES_WORKER !== "undefined") {
      console.log("Using Cloudflare Worker binding");
      try {
        response = await PARTNER_RESOURCES_WORKER.fetch(endpoint, {
          method,
          body: requestBody,
          headers,
        });
        console.log("Worker binding request successful:", {
          status: response.status,
          ok: response.ok,
        });
      } catch (error) {
        console.error("Worker binding request failed:", error);
      }
    } else {
      const PARTNER_RESOURCES_WORKER_URL = import.meta.env
        .VITE_PARTNER_RESOURCES_WORKER_URL;
      console.log(
        "Using standard fetch with URL:",
        PARTNER_RESOURCES_WORKER_URL
      );

      if (!PARTNER_RESOURCES_WORKER_URL) {
        console.error(
          "Missing PARTNER_RESOURCES_WORKER_URL environment variable"
        );
        throw new Error(
          "PARTNER_RESOURCES_WORKER_URL is not defined in environment variables"
        );
      }

      try {
        const fullUrl = `${PARTNER_RESOURCES_WORKER_URL}${endpoint}`;
        console.log("Making fetch request to:", fullUrl);
        console.log("Request config:", {
          method,
          headers,
          bodyType: requestBody instanceof FormData ? "FormData" : "JSON",
        });

        response = await fetch(fullUrl, {
          method,
          body: requestBody,
          headers,
        });

        console.log("Fetch request successful:", {
          status: response.status,
          ok: response.ok,
          statusText: response.statusText,
        });
      } catch (error) {
        console.error("Fetch request failed:", error);
        throw error;
      }
    }

    console.log("========= Exiting callPartnerResourcesWorker =========");

    if (!response) {
      console.error("No response received from worker");
      throw new Error("Failed to get response from PARTNER_RESOURCES_WORKER");
    }

    return response;
  };

  // In PartnerConfiguration.jsx
  const fetchPartnerData = async (partnerId) => {
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await callPartnerResourcesWorker(
        `/get-partner?id=${partnerId}`,
        "GET"
      );

      if (!response.ok) throw new Error("Failed to fetch partner data");

      const data = await response.json();
      setPartner({
        id: partnerId,
        name: data.name,
        description: data.description || "",
        theme: data.theme,
        resourceLinks: data.resourceLinks || [],
        fineTuningText: data.fineTuningText || "",
        welcomeMessage: data.welcomeMessage || "",
        logo: null,
        existingLogoUrl: data.theme.logo, // Store the existing logo URL
        isPublic: data.isPublic !== undefined ? data.isPublic : true,
      });
      setSuccessMessage("Partner data loaded successfully");
    } catch (error) {
      console.error("Error fetching partner:", error);
      setError("Failed to fetch partner data. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleThemeChange = (e) => {
    const { name, value } = e.target;
    setPartner((prev) => ({
      ...prev,
      theme: {
        ...prev.theme,
        [name]: value,
      },
    }));
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPartner((prev) => ({
        ...prev,
        logo: file,
      }));
    }
  };

  const handleResourceLinkChange = (e) => {
    const { name, value } = e.target;
    setResourceLink((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addResourceLink = () => {
    if (resourceLink.title && resourceLink.url) {
      const newResourceLink = { ...resourceLink };
      if (resourceLink.type === "attachment") {
        newResourceLink.filename = newResourceLink.title;
        delete newResourceLink.title;
      }

      setPartner((prev) => {
        const updatedLinks = [...prev.resourceLinks];
        if (typeof resourceLink.editIndex === "number") {
          // Update existing link
          updatedLinks[resourceLink.editIndex] = newResourceLink;
        } else {
          // Add new link
          updatedLinks.push(newResourceLink);
        }
        return {
          ...prev,
          resourceLinks: updatedLinks,
        };
      });

      setResourceLink({ type: "url", title: "", url: "" });
      setEditingResourceLink(null);
    }
  };

  const editResourceLink = (index) => {
    const link = partner.resourceLinks[index];
    setResourceLink({
      type: link.type || "url",
      title: link.title || link.filename || "",
      url: link.url || "",
      editIndex: index,
    });
    setEditingResourceLink(index);
  };

  const deleteResourceLink = (index) => {
    setPartner((prev) => ({
      ...prev,
      resourceLinks: prev.resourceLinks.filter((_, i) => i !== index),
    }));
  };

  const handleFineTuningTextChange = (content) => {
    if (content.length <= MAX_FINE_TUNING_CHARS) {
      setPartner((prev) => ({
        ...prev,
        fineTuningText: content,
      }));
    } else {
      setError(
        `Fine-tuning text must be ${MAX_FINE_TUNING_CHARS} characters or less.`
      );
    }
  };

  const clearForm = () => {
    setPartner(initialPartnerState);
    setResourceLink({ type: "url", title: "", url: "" });
    setError(null);
    setSuccessMessage("");
  };

  const handleDescriptionChange = (content) => {
    setPartner((prev) => ({
      ...prev,
      description: content,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    let logoUrl = "";
    if (partner.logo) {
      console.log("Logo file before FormData:", partner.logo);
      const formData = new FormData();
      formData.append("file", partner.logo);

      try {
        const response = await callPartnerResourcesWorker(
          "/upload-to-r2",
          "POST",
          formData
        );
        if (!response.ok) throw new Error("Failed to upload logo");
        const data = await response.json();
        logoUrl = data.url;
      } catch (error) {
        console.error("Error uploading logo:", error);
        setError("Failed to upload logo. Please try again.");
        setIsSubmitting(false);
        return;
      }
    }

    const partnerData = {
      id: partner.id,
      config: {
        theme: {
          primaryColor: partner.theme.primaryColor,
          secondaryColor: partner.theme.secondaryColor,
          headerColor: partner.theme.headerColor,
          logo: logoUrl || partner.theme.logo, // Use existing logo if no new one uploaded
        },
        name: partner.name,
        description: partner.description,
        resourceLinks: partner.resourceLinks,
        fineTuningText: partner.fineTuningText,
        welcomeMessage: partner.welcomeMessage,
        isPublic: partner.isPublic,
      },
    };

    try {
      // Changed from /upsert-partner to /insert-partner to match backend
      const response = await callPartnerResourcesWorker(
        "/insert-partner",
        "POST",
        partnerData
      );

      if (!response.ok) throw new Error("Failed to save partner configuration");

      setSuccessMessage("Partner configuration saved successfully");
      if (!searchPartnerId) {
        clearForm(); // Only clear if it's a new partner
      }
    } catch (error) {
      setError("Failed to save partner data. Please try again.");
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit} className="partner-config-container">
      <h2 className="partner-config-title">Partner Configuration</h2>

      <div className="form-section">
        <h3 className="form-section-title">Search Partner</h3>
        <div className="search-section">
          <div className="search-container">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Enter Partner ID to load"
                className="search-input"
                value={searchPartnerId}
                onChange={(e) => setSearchPartnerId(e.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={() =>
                searchPartnerId && fetchPartnerData(searchPartnerId)
              }
              className="search-button"
              disabled={!searchPartnerId || isSubmitting}
            >
              {isSubmitting ? "Loading..." : "Load Partner"}
            </button>
          </div>
        </div>
        <h3 className="form-section-title">Basic Information</h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Input
            label="Partner_User_ID"
            name="id"
            value={partner.id}
            onChange={handleChange}
            required
          />
          <Input
            label="Name_Partner"
            name="name"
            value={partner.name}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="form-section">
  <h3 className="form-section-title">Visibility Settings</h3>
  <div className="visibility-toggle-container">
    <div className="toggle-wrapper">
      <span className="toggle-label">Community Visibility</span>
      <label className="toggle-switch">
        <input
          type="checkbox"
          name="isPublic"
          checked={partner.isPublic}
          onChange={(e) =>
            setPartner((prev) => ({
              ...prev,
              isPublic: e.target.checked,
            }))
          }
        />
        <span className="toggle-slider"></span>
        <span className="toggle-status">{partner.isPublic ? "Public" : "Private"}</span>
      </label>
    </div>
    <p className="toggle-description">
      {partner.isPublic
        ? "This partner will be visible in the community directory"
        : "This partner will only be accessible with a direct link"}
    </p>
  </div>
</div>

      <div className="form-section">
        <h3 className="form-section-title">Description</h3>
        <ReactQuill
          value={partner.description}
          onChange={handleDescriptionChange}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image"],
              ["clean"],
            ],
          }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
          ]}
          className="description-editor"
        />
      </div>

      <div className="form-section">
        <h3 className="form-section-title">Theme Colors</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {["primaryColor", "secondaryColor", "headerColor"].map(
            (colorType) => (
              <div key={colorType} className="color-input-group">
                <label>
                  {colorType.charAt(0).toUpperCase() + colorType.slice(1)}
                </label>
                <input
                  type="color"
                  name={colorType}
                  value={partner.theme[colorType]}
                  onChange={handleThemeChange}
                />
                <input
                  type="text"
                  name={colorType}
                  value={partner.theme[colorType]}
                  onChange={handleThemeChange}
                />
              </div>
            )
          )}
        </div>
      </div>

      <div className="form-section">
        <h3 className="form-section-title">Logo</h3>
        <div className="logo-upload-container">
          {partner.existingLogoUrl && (
            <div className="existing-logo">
              <img
                src={partner.existingLogoUrl}
                alt="Current Logo"
                style={{ maxHeight: "100px", marginBottom: "10px" }}
              />
              <p>Current Logo</p>
            </div>
          )}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleLogoUpload}
            accept="image/*"
            style={{ display: "none" }}
          />
          <button
            type="button"
            onClick={() => fileInputRef.current.click()}
            className="logo-upload-button"
          >
            {partner.existingLogoUrl ? "Replace Logo" : "Upload Logo"}
          </button>
          {partner.logo && (
            <span className="logo-filename">New file: {partner.logo.name}</span>
          )}
        </div>
      </div>

      <div className="form-section">
        <h3 className="form-section-title">Resource Links</h3>
        <div className="resource-link-form">
          <select
            name="type"
            value={resourceLink.type}
            onChange={handleResourceLinkChange}
            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
          >
            <option value="url">URL</option>
            <option value="attachment">Attachment</option>
          </select>
          <Input
            name="title"
            value={resourceLink.title}
            onChange={handleResourceLinkChange}
            placeholder={
              resourceLink.type === "url" ? "Link Title" : "Filename"
            }
          />
          <Input
            name="url"
            value={resourceLink.url}
            onChange={handleResourceLinkChange}
            placeholder="URL"
          />
          <button
            type="button"
            onClick={addResourceLink}
            className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
          >
            {editingResourceLink !== null
              ? "Update Resource Link"
              : "Add Resource Link"}
          </button>
        </div>
        {partner.resourceLinks.map((link, index) => (
          <div key={index} className="resource-link-item">
            <div className="resource-link-info">
              <p className="resource-link-type">
                {link.type === "url" ? "URL" : "Attachment"}
              </p>
              <p className="resource-link-title">
                {link.title || link.filename}
              </p>
              <p className="resource-link-url">{link.url}</p>
            </div>
            <div className="resource-link-actions">
              <button
                type="button"
                onClick={() => editResourceLink(index)}
                className="edit-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => deleteResourceLink(index)}
                className="delete-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="form-section">
        <h3 className="form-section-title">Welcome Message</h3>
        <textarea
          name="welcomeMessage"
          value={partner.welcomeMessage}
          onChange={handleChange}
          placeholder="Enter a welcome message for your users"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
          rows="4"
        />
      </div>

      <div className="form-section">
        <h3 className="form-section-title">Fine-Tuning Text</h3>
        <ReactQuill
          value={partner.fineTuningText}
          onChange={handleFineTuningTextChange}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image"],
              ["clean"],
            ],
          }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
          ]}
          className="fine-tuning-editor"
        />
        <p className="text-sm text-gray-500 mt-2">
          {partner.fineTuningText.length} / {MAX_FINE_TUNING_CHARS} characters
        </p>
      </div>

      <div className="mt-8 flex justify-between">
        <button type="submit" disabled={isSubmitting} className="submit-button">
          {isSubmitting ? "Submitting..." : "Submit Partner Configuration"}
        </button>
        <button type="button" onClick={clearForm} className="clear-button">
          Clear Form
        </button>
      </div>

      {error && (
        <div className="error-message">
          <p>Error</p>
          <p>{error}</p>
        </div>
      )}

      {successMessage && (
        <div className="success-message">
          <p>{successMessage}</p>
        </div>
      )}
    </form>
  );
};

export default PartnerConfiguration;
